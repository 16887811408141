import { AccessibleIcon } from '@radix-ui/react-accessible-icon'
import * as Collapsible from '@radix-ui/react-collapsible'
import clsx from 'clsx'
import { Link } from '~/components/link'
import { routes } from '~/config/routes'

import { useHomepageFeatures } from '../../hooks/use-homepage-features'
import { Container } from '../container'
import IconArrow from './assets/icon-arrow.svg'
import IconFacebook from './assets/icon-facebook.svg'
import IconTwitter from './assets/icon-twitter.svg'
import Logo from './assets/logo.svg'
import { CollapsibleTrigger } from './style'

interface Props {}

const RESOURCES = {
  name: 'Resources',
  features: [
    {
      name: 'Help Center',
      href: 'https://help.kindest.com',
      external: true,
    },
    {
      name: 'Roadmap',
      href: 'https://portal.productboard.com/kindest1/1-product-portal',
      external: true,
    },
    {
      name: 'Blog',
      href: 'https://resources.kindest.com/',
      external: true,
    },
    // {
    //   name: 'Donor Support',
    //   href: 'mailto:support@kindest.com',
    //   external: true,
    // },
    {
      name: 'Terms of Service Agreement',
      href: routes.legal.terms,
      external: true,
    },
    {
      name: 'Privacy Policy',
      href: routes.legal.privacyPolicy,
      external: true,
    },
    {
      name: 'Our Commitment',
      href: routes.ourCommitment,
      external: true,
    },
  ],
}

export const SiteFooter = (props: Props) => {
  const {} = props

  const homepageFeatures = useHomepageFeatures()

  const links = [...homepageFeatures, RESOURCES]

  return (
    <footer>
      <div className="py-[5.375rem] bg-primary">
        <Container>
          <h3 className="mb-4 text-3xl font-bold text-center text-white leading-10">
            Start a Free Trial with Kindest
          </h3>
          <p className="mb-12 text-center text-white leading-8 text-base">
            Elevate your fundraising with easy to create donor-friendly pages
            and essential transaction reports that empower you to
            <br />
            engage effectively with your donors. Start maximizing your impact
            today.
          </p>

          <div className="flex flex-col space-y-4 md:justify-center md:space-y-0 md:space-x-4 md:flex-row">
            <Link href={routes.bookDemo} external>
              <a
                className={clsx(
                  'bg-white text-primary text-sm font-medium border border-transparent rounded-sm w-full md:w-[190px] h-[56px] flex justify-center items-center transition-colors',
                  'hover:bg-primary hover:text-white hover:border-white'
                )}
              >
                Contact Sales
              </a>
            </Link>
          </div>
        </Container>
      </div>

      <Container className="lg:pt-20 px-0 md:px-[30px]">
        <ul className="divide-y-2 lg:divide-none lg:pb-20 lg:flex lg:justify-between">
          {links.map(section => {
            const content = (
              <ul className="space-y-3 md:space-y-2">
                {section.features.map((feature: any) => {
                  return (
                    <li key={feature.name}>
                      <Link
                        href={feature.href ?? (section as any).href}
                        hash={feature.id}
                        external={feature.external}
                      >
                        <a className="text-sm">{feature.name}</a>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            )

            return (
              <li key={section.name}>
                <>
                  <div className="hidden lg:block">
                    <h3 className="text-base font-medium mb-7">
                      {section.name}
                    </h3>
                    {content}
                  </div>

                  <Collapsible.Root className="lg:hidden">
                    <CollapsibleTrigger className="relative block w-full px-8 py-6 text-base font-medium text-left">
                      {section.name}
                      <IconArrow
                        aria-hidden
                        className="absolute transition-transform right-8 top-8"
                      />
                    </CollapsibleTrigger>
                    <Collapsible.Content className="px-8 pb-6">
                      {content}
                    </Collapsible.Content>
                  </Collapsible.Root>
                </>
              </li>
            )
          })}
        </ul>
      </Container>

      <div className="border-t-2">
        <Container className="flex flex-col items-center justify-between py-8 md:flex-row">
          <div className="flex mb-6 space-x-5 md:mb-0">
            <a
              href="https://twitter.com/kindest"
              rel="noopener noreferrer"
              target="_blank"
            >
              <AccessibleIcon label="Kindest Twitter">
                <IconTwitter />
              </AccessibleIcon>
            </a>
            <a
              href="https://facebook.com/kindestcom"
              rel="noopener noreferrer"
              target="_blank"
            >
              <AccessibleIcon label="Kindest Facebook">
                <IconFacebook />
              </AccessibleIcon>
            </a>
          </div>
          <div className="flex flex-col-reverse items-center md:flex-row">
            <span className="text-sm md:mr-8">
              © {new Date().getUTCFullYear()} Kindest Nonprofit Solutions, Inc.
            </span>
            <Logo className="mb-4 md:mb-0" />
          </div>
        </Container>
      </div>
    </footer>
  )
}
