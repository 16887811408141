import { AccessibleIcon } from '@radix-ui/react-accessible-icon'
import clsx from 'clsx'
import { Button } from '~/components/button'
import { Link } from '~/components/link'
import { env } from '~/config/env'
import { routes } from '~/config/routes'
import { useWindowScroll } from '~/hooks/use-window-scroll'
import Logo from './assets/logo.svg'
import { MobileMenu } from './components/mobile-menu'
import { NavLink } from './components/nav-link'
import { NavMenu } from './components/nav-menu'

interface Props {}

// As of now, we cannot directly import the KindestDonationForm component from the Kindest package, because it's built as a custom element and not a React component.
// Even with <script> tag, the component is not known at the time of compilation.
// This allows us to render the component as a string of HTML.
// TODO: Replace with a proper React component when available.
const KindestDonationFormWrapper = () => {
  if (env.environment !== 'staging') {
    return null
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: `<kindest-donation-form page="334556-test" />` }} />
  )
};

export const SiteHeader = (props: Props) => {
  const {} = props

  const scroll = useWindowScroll()

  return (
    <>
      <div
        className={clsx(
          'px-6 py-5 fixed top-0 z-50 inset-x-0 transition-colors',
          {
            'bg-white': scroll.y > 1,
            'border-b': scroll.y > 1,
          }
        )}
      >
        <div className="flex items-center ">
          <div className="relative z-50 flex-1">
            <Link href="/">
              <a>
                <AccessibleIcon label="Kindest home page">
                  <Logo />
                </AccessibleIcon>
              </a>
            </Link>
          </div>
          <div className="flex-1 hidden lg:block">
            <ul className="flex justify-center space-x-10">
              <li>
                <NavLink href={routes.features.all}>Features</NavLink>
              </li>
              <li>
                <NavLink href={routes.integrations.all}>Integrations</NavLink>
              </li>
              <li>
                <NavLink href={routes.pricing}>Pricing</NavLink>
              </li>
              <li>
                <NavLink href="https://resources.kindest.com" external>
                  Resources
                </NavLink>
              </li>
              <li>
                <NavMenu>Login</NavMenu>
              </li>
            </ul>
          </div>
          <div className="justify-end flex-1 hidden space-x-2 lg:flex">
            <script async src="https://kindest-scripts-prod.s3.amazonaws.com/index.umd.js" crossOrigin="anonymous"></script>
            <KindestDonationFormWrapper />

            <Button href={routes.bookDemo} external>
              Contact Sales
            </Button>
          </div>
          <MobileMenu />
        </div>
      </div>
      {/* TODO: automate banner <MaintenanceBanner /> */}
      {/* Global banner visible from homepage and Dashboard */}
      <div
        style={{
          marginTop: '5.5rem',
        }}
      >
        {/* TODO: just like MaintenanceBanner, find a way how to automate banner distribution <WebinarBanner /> */}
      </div>
    </>
  )
}
